import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import classnames from 'classnames';
import { TabSet, TabPane } from '@dotone/react-ui-core';

const toTabItems = (conversionSteps) => {
  const items = {};
  conversionSteps.forEach((conversionStep) => {
    items[conversionStep.id] = conversionStep.label;
    return null;
  });
  return items;
};

const DocsConversionStepTabset = ({
  conversionSteps,
  renderer,
  pixelParams,
  onChange,
  ...otherProps
}) => {
  const [activeTab, setActiveTab] = useState(conversionSteps[0].id.toString());
  const items = toTabItems(conversionSteps);
  const current = conversionSteps.find((step) => step.id.toString() === activeTab);

  const handleChange = (tab) => {
    setActiveTab(tab);
    if (onChange) onChange(tab);
  };

  useEffectOnce(() => {
    if (onChange) onChange(activeTab);
  });

  return (
    <TabSet
      card={false}
      items={items}
      activeTab={activeTab}
      navProps={{
        pills: true,
        className: classnames('font-medium custom-pills', { 'mb-2': conversionSteps.length > 1 }),
        linkProps: { className: classnames('py-0', { 'd-none': conversionSteps.length < 2 }) },
      }}
      onToggle={handleChange}
      {...otherProps}
    >
      <TabPane tabId={activeTab} className="p-0">
        {renderer(current)}
      </TabPane>
    </TabSet>
  );
};

export default DocsConversionStepTabset;
