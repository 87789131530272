const statTranslations = ['stat', 'order'];

module.exports = {
  locales: ['en-US', 'id-ID', 'ms-MY', 'th-TH', 'vi-VN', 'zh-CN', 'zh-TW'],
  defaultLocale: 'en-US',
  localeDetection: true,
  logger: false,
  // redirectToDefaultLang: true,
  pages: {
    '*': ['common', 'notify', 'tour'],
    'rgx:^/advertisers/reports/': ['statSummary'],
    'rgx:^/advertisers/easy-stores/*': ['easyStoreSetup'],
    '/advertisers': ['dashboard', 'imageCreative', 'textCreative', 'affiliate'],
    '/advertisers/affiliates': ['affiliate'],
    '/advertisers/announcements': ['feed'],
    '/advertisers/api-keys': ['apiKey'],
    '/advertisers/balances': ['advertiserBalance'],
    '/advertisers/banners': ['imageCreative'],
    '/advertisers/campaigns': ['campaign', 'affiliate'],
    '/advertisers/documentation/modify-order': ['docs'],
    '/advertisers/documentation/tracking': ['docs'],
    '/advertisers/downloads': ['download'],
    '/advertisers/faqs': ['faqFeed'],
    '/advertisers/login': ['login'],
    '/advertisers/offers': ['offer'],
    '/advertisers/order-inquiries': ['missingOrder', 'order', 'stat'],
    '/advertisers/products': ['product'],
    '/advertisers/profile': ['profile', 'network'],
    '/advertisers/promo-deals': ['textCreative'],
    '/advertisers/reports/affiliation': ['affiliation'],
    '/advertisers/register': ['network', 'register'],
    '/advertisers/register/success': ['register'],
    '/advertisers/register/verification': ['register'],
    '/advertisers/register/verify': ['register'],
    '/advertisers/transactions/captured': statTranslations,
    '/advertisers/transactions/clicks': statTranslations,
    '/advertisers/transactions/confirmed': statTranslations,
    '/advertisers/transactions/pending': ['stat'],
    '/advertisers/transactions/pending/by-offer/[offerId]': ['stat'],
    '/advertisers/transactions/published': statTranslations,
    '/advertisers/transactions/search': statTranslations,
    '/advertisers/uploads': ['upload'],
    '/advertisers/uploads/instructions': ['uploadInstruction'],
  },
};
