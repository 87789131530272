import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useRouter } from 'next/router';
import { useTranslate, useCurrentUser, useFilterSelector, useSearchSelector } from '@hooks';
import { useForm } from '@dotone/react-ui-core/hooks';
import { getMktSiteCode, searchOffers } from '@actions';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import Trans from 'next-translate/Trans';
import { Row, Col, FormGroup, Label, Form } from 'reactstrap';
import { InputClipboard, CodeClipboard, MessageEmpty } from '@dotone/react-ui-core';
import DocsConversionStepTabset from './DocsConversionStepTabset';
import { OfferSearchInput } from '../forms';

const Clipboard = (props) => <CodeClipboard className="pb-2" language="html" {...props} />;

const Translate = ({ tKey, components, code, clipboardProps, ...otherProps }) => (
  <Trans
    i18nKey={`docs:gtmScript.${tKey}`}
    components={{
      h5: <h5 className="text-dark-light" />,
      li: <li />,
      ol: <ol />,
      strong: <strong />,
      em: <em />,
      code: <Clipboard value={code} {...clipboardProps} />,
      ...components,
    }}
    {...otherProps}
  />
);

const RenderTranslate = ({ conversionStep, ...otherProps }) => {
  const { id, conversionPixel, label } = conversionStep;
  return (
    <Translate
      key={id}
      tKey="conversionPixel"
      code={conversionPixel}
      values={{ label }}
      {...otherProps}
    />
  );
};

const DocsGtmScript = () => {
  const { t, locale } = useTranslate('docs', 'gtmScript');
  const dispatch = useDispatch();
  const router = useRouter();
  const { currentUser } = useCurrentUser();
  const { connectForm, watch, setValue } = useForm();
  const offerId = watch('offerId');
  const { mkt_site_id: givenMktSiteId } = router.query;

  const { data } = useSearchSelector(({ offerSearch }) => offerSearch, {
    withMktSite: true,
    active: true,
    locale,
  });
  const selectedOffer = data.find((offer) => offer.id.toString() === offerId?.toString());
  const mktSiteId = offerId ? selectedOffer?.mktSiteId : givenMktSiteId;

  const { data: mktSite } = useFilterSelector(({ mktSiteCode }) => mktSiteCode, {
    id: mktSiteId,
    locale,
  });
  const { offer, network, browsePixel, gtmScript } = mktSite || {};
  const { conversionSteps } = offer || {};

  useDeepCompareEffect(() => {
    if (!givenMktSiteId && currentUser.id) {
      dispatch(
        searchOffers({
          withMktSite: true,
          active: true,
          locale,
        })
      );
      if (!offerId) setValue('offerId', data[0]?.id);
    }
  }, [dispatch, offerId, data.length, currentUser.id]);

  useEffect(() => {
    if (mktSiteId && !mktSite?.id) {
      dispatch(getMktSiteCode(mktSiteId, { locale }));
    }
  }, [dispatch, mktSiteId, mktSite?.id, locale]);

  return (
    <>
      {!givenMktSiteId && data.length > 1 && (
        <Form>
          {connectForm(
            <FormGroup>
              <Label>{t('common:model.offer')}</Label>
              <OfferSearchInput active searchParams={{ withMktSite: true }} name="offerId" />
            </FormGroup>
          )}
        </Form>
      )}
      {mktSite?.id ? (
        <>
          <Row>
            <Col md={6}>
              <InputClipboard
                prepend={t('common:model.offer')}
                value={getNameWithId(offer)}
                className="mb-3"
                iconButton
              />
            </Col>
            <Col md={6}>
              <InputClipboard
                prepend={t('common:model.network')}
                value={getNameWithId(network)}
                className="mb-3"
                iconButton
              />
            </Col>
          </Row>
          <Translate tKey="newDataLayer" />
          <Translate tKey="browsePixel" code={browsePixel} />
          <Translate
            tKey="conversionPixels"
            components={{
              code: (
                <div>
                  <DocsConversionStepTabset
                    withActions
                    conversionSteps={conversionSteps}
                    renderer={(conversionStep) => (
                      <RenderTranslate conversionStep={conversionStep} className="border-top" />
                    )}
                  />
                </div>
              ),
            }}
          />
          <Translate
            tKey="gtmScript"
            code={gtmScript}
            clipboardProps={{ language: 'javascript' }}
          />
        </>
      ) : (
        <MessageEmpty />
      )}
    </>
  );
};

export default DocsGtmScript;
